// Backgrounds

$background-modern: #141414;
$background-webkit: #141414;
$background-fallback: #141414;

// Fonts & Text

$font-title: 'Gugi', cursive;
$font-base: 'Roboto', sans-serif;

$color-base: white;
$color-hover: #7F7F7F;
