@import 'variables';

body {
  color: $color-base;
  font-family: $font-base;
  margin: 0;
  padding: 0;
  background: $background-fallback;  /* fallback for old browsers */
  background: $background-webkit;    /* Chrome 10-25, Safari 5.1-6 */
  background: $background-modern;    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

h1, h2 {
  font-family: $font-title;
}

h1 {
  font-size: 75px;
  padding-top: 45px;
  margin-bottom: 20px;
}

h2 {
  font-size: 50px;
  margin-bottom: 25px;
}

header {
  margin-top: 60px;
}

a {
  color: $color-base;
  text-decoration: none;
  outline: none;
}

a:hover {
  color: $color-hover;
  outline: none;
}

a:focus {
  outline: none;
}

.container {
  // padding: 0 75px;
  user-select: none;
}

.name, .position {
  cursor: pointer;
}

.btn-findme {
  font-size: 52px;
  padding: 20px 40px 0;
}

.btn-findme:hover {
  color: $color-hover;
}

.title {
  padding-bottom: 15px;
  margin-top: 20vh;
}

@media (max-width: 767.2px), (max-height: 767.2px) {
  .title {
    margin-top: 0vh; /* For smaller screens */
  }
}

.background-video {
  position: fixed;
  top: 0;
  left: 0;
  object-fit: cover; // Ensure the video fills the screen without distortion
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.content {
  position: relative;
  z-index: 1;
  color: white;
  text-align: center;
  padding: 20px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.icon-text {
  font-size: 18px;
  margin-top: 0;
  text-decoration: none;
  flex-grow: 1;
}

.icon-links {
  padding: 0 60px 0 60px;
}

/* Icons and text side-by-side and increase text size for screens <= 767px */
@media (max-width: 767.2px) {
  .icon-links {
    display: flex;
    flex-direction: row; /* Side-by-side icon and text */
    justify-content: center; /* Center align the items */
    align-items: center; /* Vertically align the items */
    gap: 20px; /* Add space between items */
    flex-wrap: wrap; /* Allow wrapping if necessary */
    color: white;
    text-decoration: none;
    transition: all 0.3s ease;
    margin-top: 5px;
    padding: 0;
  }

  .icon-links a {
    width: 400px; /* Set a fixed width for the link boxes */
    padding: 0px 25px 0px;
    border: 2px solid #fff;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.2);
    text-align: left; /* Align text to the left */
    display: flex;
    align-items: center; /* Center the icon vertically */
    justify-content: flex-start; /* Align icon to the left and text to the right */
    transition: all 0.3s ease;
  }

  .icon-links a i {
    margin-right: 30px; /* Space between icon and text */
    font-size: 35px; /* Icon size */
    width: 30px; /* Fixed width for icons to ensure alignment */
    height: 30px; /* Fixed height to make icons square */
    line-height: 30px; /* Center the icon within its box */
    display: inline-block; /* Ensure icons behave like a block element */
    text-align: center; /* Center align the icon content */
    margin-left: 10px;
  }

  .icon-links a:hover {
    background-color: rgba(255, 255, 255, 0.4); /* Hover effect */
  }

  .icon-links a .icon-text {
    font-size: 24px; /* Keep text size smaller to fit inside the box */
    text-align: left; /* Align text to the left */
    margin-top: 15px; /* Remove any extra margin */
    flex-grow: 1;
  }
}
